import styled from '@emotion/styled'
import { Item, Props as ItemProps } from 'app/components/Accordions/Item'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const Faq = memo(function Faq({ items, title }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      <List allowZeroExpanded>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 15.25rem 16.667vw 12.5rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media (max-width: 1023px) {
    padding-top: 9.0625rem;
    padding-bottom: 7.375rem;
  }
`

const Title = styled.div`
  max-width: 40rem;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  margin: auto;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const List = styled(Accordion)`
  margin-top: 5rem;
  text-align: left;
`
